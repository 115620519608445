<template>
  <v-card class="py-5">
    <v-card-title class="justify-center text-h4 pb-5 primary--text">
      ยืนยันรีเซ็ตข้อมูล
    </v-card-title>
    <v-card-text class="popup-background">
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field
            class="pt-2 pb-0"
            name="note"
            v-model="item.note"
            label="หมายเหตุ"
            outlined
            dense
            background-color="white"
            autocomplete="off"
          />
        </v-col>
      </v-row>
      <v-row v-if="alert" justify="center" class="my-0 mx-0 py-0">
        <p class="error--text">กรุณาใส่หมายเหตุ</p>
      </v-row>
      <v-row>
        <v-col cols="10">
          <v-radio-group v-model="item.note" class="my-0">
            <v-radio
              v-for="note in noteOptions"
              :key="note" 
              :label="note" 
              :value="note"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-5">
      <v-spacer/>
        <v-btn
          outlined
          class="px-10 mr-12 ml-9"
          color="primary"
          @click="save()"
        >
          Confirm
        </v-btn>
        <v-btn
          outlined
          class="px-10 mr-10"
          color="grey"
          @click="close()"
        >
          Cancel
        </v-btn>
      <v-spacer/>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: '',
      alert: false,
      noteOptions: ["เก็บเงินผิด",
      "ต่อภาษีไม่สำเร็จ ไม่ชำระค่าปรับ",
      "ต่อภาษีไม่สำเร็จ ยังไม่ถึงกำหนดชำระภาษี",
      "ทะเบียนผิด ออกแทน (ทะเบียนที่ถูกต้อง)",
      "ปรับรอบพรบ.ผิด ราคาไม่ตรง",
      "เปลี่ยนการชำระ เงินสด เป็น โอน",
      "ยกเลิกใบตรวจก๊าซ ถังหมดอายุ",
      "ยกเลิกใบตรวจสภาพ ช่างดูปีตรวจผิด",
      "ยกเลิกใบตรวจสภาพ ทะเบียนถูกระงับ",
      "ลูกค้าขอยกเลิก มีพรบ.อยู่แล้ว",
      "ลูกค้าไม่ทำพรบ. ช่างติ๊กผิด",
      "เลือกรย. ผิด รย.1 เป็น รย.3",
      "เลือกสินค้าผิด ตรวจก๊าซ CNG เป็น LPG",
      "เลือกสินค้าผิด ตรวจก๊าซ LPG เป็น CNG",
      "เลือกสินค้าผิด(ระบุรายการที่ผิด) เป็น (รายการที่ถูกต้อง เช่น",
      "ใส่จำนวนเงินผิด ขึ้นค้างชำระ",
      "ใส่บิลผิด ลูกค้าไม่ได้ตรวจสภาพ",
      "ใส่บิลผิด ลูกค้าไม่ได้ทำพรบ.",
      "ออกบิลซ้ำ"]
    };
  },
  created(){
    console.log('DocumentResetWithNote')
    console.log(this.item)
  },
  methods: {
    close() {
      this.$emit('onClose');
    },
    save() {
      // this.$v.form.$touch();
      // if (this.$v.form.$error) return;
      console.log(this.item)
      if(this.item.note==''){
        this.alert = true
      }else{
        this.$emit('onSubmit', this.item);
      }
    },
  },
};
</script>
