var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"mb-5",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h5 orange--text",staticStyle:{"width":"180px"}},[_vm._v("ใบแจ้งหนี้ (ค้างชำระ)")]),_c('div',{staticStyle:{"width":"330px"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"prepend-inner-icon":"mdi-magnify","label":"ชื่อลูกค้า ทะเบียน ชื่อโปรเจ็ค เบอร์โทร","outlined":"","rounded":"","dense":"","single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleEnter.apply(null, arguments)}},model:{value:(_vm.filter.start_with),callback:function ($$v) {_vm.$set(_vm.filter, "start_with", $$v)},expression:"filter.start_with"}})],1),_c('v-spacer'),_c('v-row',{staticClass:"mt-2"},[_c('v-checkbox',{attrs:{"label":"เฉพาะวันนี้"},on:{"change":function($event){return _vm.changeToday(_vm.toDay)}},model:{value:(_vm.toDay),callback:function ($$v) {_vm.toDay=$$v},expression:"toDay"}})],1),_c('div',{staticStyle:{"width":"140px"}},[(_vm.toDay == false)?_c('DatePicker',{attrs:{"label":"วันเริ่มต้น","value":_vm.filter.start_date,"hideDetails":""},on:{"onChange":function (val) {
            _vm.filter.start_date = val;
            _vm.getData(true);
          }}}):_vm._e()],1),_c('div',{staticStyle:{"width":"140px"}},[(_vm.toDay == false)?_c('DatePicker',{attrs:{"label":"วันสิ้นสุด","value":_vm.filter.end_date,"hideDetails":""},on:{"onChange":function (val) {
            _vm.filter.end_date = val;
            _vm.getData(true);
          }}}):_vm._e()],1),_c('v-spacer'),_c('div',{staticStyle:{"width":"150px"}},[_c('v-select',{attrs:{"label":"สถานะ","items":_vm.status,"item-value":"value","item-text":"text","outlined":"","dense":"","hide-details":""},on:{"change":function($event){return _vm.getData(true)}},model:{value:(_vm.filter.status),callback:function ($$v) {_vm.$set(_vm.filter, "status", $$v)},expression:"filter.status"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"ml-5",attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.add()}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" เพิ่มรายการ")],1)],1),_c('v-data-table',{staticClass:"elevation-2 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list .map(function (x) { return x._id; }) .indexOf(item._id) + 1 + _vm.filter.item_per_page * (_vm.filter.page - 1))+" ")]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [(!_vm.loading)?_c('menu-ship-document',{attrs:{"small":"","id":item._id,"statusId":item.status,"docTypeId":_vm.docTypeId},on:{"success":function($event){return _vm.getData()}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}}):_vm._e()]}},{key:"item.date_of_issue",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date_of_issue))+" ")]}},{key:"item.price_list.inspection_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price_list.inspection_price,'0,0.00'))+" ")]}},{key:"item.price_list.third_insurance_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price_list.third_insurance_price,'0,0.00'))+" ")]}},{key:"item.price_list.tax_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price_list.tax_price,'0,0.00'))+" ")]}},{key:"item.price_list.insurance_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price_list.insurance_price,'0,0.00'))+" ")]}},{key:"item.price_list.other_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.price_list.other_price,'0,0.00'))+" ")]}},{key:"item.net_price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("numeral")(item.net_price,'0,0.00'))+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"grey","small":"","outlined":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.print(item)}}},[_c('v-list-item-title',[_vm._v("พิมพ์")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v("แก้ไข")])],1),_c('v-list-item',{attrs:{"dense":"","link":""},on:{"click":function($event){_vm.dialogResetData = {
                document_id: item._id,
                note: '',
              }, _vm.dialogReset = true}}},[_c('v-list-item-title',[_vm._v("รีเซ็ท")])],1)]],2)],1)]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)}),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"500"},model:{value:(_vm.dialogReset),callback:function ($$v) {_vm.dialogReset=$$v},expression:"dialogReset"}},[_c('DocumentResetWithNote',{attrs:{"item":_vm.dialogResetData},on:{"onClose":function($event){_vm.dialogReset = false},"onSubmit":_vm.onSubmitReset}})],1),(_vm.usedPrint && _vm.lists.setting.receipt_printing_format == 0)?_c('PrintDocument',{key:_vm.printKey,ref:"printDocument",attrs:{"docTypeId":_vm.docTypeId,"type":_vm.docType,"data":_vm.dataPrint.main_bill,"data2":_vm.dataPrint.split_bill,"disabled":!_vm.usedPrint,"firstCreated":true}}):_vm._e(),(_vm.usedPrint && _vm.lists.setting.receipt_printing_format == 2)?_c('PrintDocument9inc',{key:_vm.printKey,ref:"printDocument",attrs:{"docTypeId":_vm.docTypeId,"type":_vm.docType,"data":_vm.dataPrint.main_bill,"data2":_vm.dataPrint.split_bill,"disabled":!_vm.usedPrint,"firstCreated":true}}):_vm._e(),(_vm.usedPrint && _vm.lists.setting.receipt_printing_format == 1)?_c('PrintDocument80mm',{key:_vm.printKey,ref:"printDocument",attrs:{"docTypeId":_vm.docTypeId,"type":_vm.docType,"data":_vm.dataPrint.main_bill,"data2":_vm.dataPrint.split_bill,"disabled":!_vm.usedPrint,"firstCreated":true}}):_vm._e(),(_vm.usedPrint && _vm.lists.setting.receipt_printing_format == 3)?_c('PrintDocumentFull',{key:_vm.printKey,ref:"printDocument",attrs:{"docTypeId":_vm.docTypeId,"type":_vm.docType,"data":_vm.dataPrint.main_bill,"data2":_vm.dataPrint.split_bill,"disabled":!_vm.usedPrint}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }