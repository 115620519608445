<template>
  <div>
    <v-toolbar dense flat class="mb-5">
      <div class="text-h5 orange--text" style="width: 180px">ใบแจ้งหนี้ (ค้างชำระ)</div>
      <div style="width: 330px">
        <v-text-field
          class="mr-2"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ชื่อลูกค้า ทะเบียน ชื่อโปรเจ็ค เบอร์โทร"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keyup.enter="handleEnter"
        ></v-text-field>
      </div>

      <v-spacer></v-spacer>
      <v-row class="mt-2">
        <v-checkbox
          v-model="toDay"
          @change="changeToday(toDay)"
          label="เฉพาะวันนี้"
        ></v-checkbox>
      </v-row>
      <div style="width: 140px">
        <DatePicker
          v-if="toDay == false"
          label="วันเริ่มต้น"
          :value="filter.start_date"
          @onChange="
            (val) => {
              filter.start_date = val;
              getData(true);
            }
          "
          hideDetails
        />
      </div>
      <div style="width: 140px">
        <DatePicker
          v-if="toDay == false"
          label="วันสิ้นสุด"
          :value="filter.end_date"
          @onChange="
            (val) => {
              filter.end_date = val;
              getData(true);
            }
          "
          hideDetails
        />
      </div>

      <v-spacer></v-spacer>

      <div style="width: 150px">
        <v-select
          label="สถานะ"
          :items="status"
          item-value="value"
          item-text="text"
          outlined
          dense
          hide-details
          v-model="filter.status"
          @change="getData(true)"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn color="primary" outlined small class="ml-5" @click="add()"
        ><v-icon left small>mdi-plus</v-icon> เพิ่มรายการ</v-btn
      >
    </v-toolbar>
    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @click:row="edit"
    >
      <template v-slot:[`item._id`]="{ item }">
        {{
          list
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) +
          1 +
          filter.item_per_page * (filter.page - 1)
        }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <menu-ship-document
          small
          v-if="!loading"
          v-model="item.status"
          :id="item._id"
          :statusId="item.status"
          @success="getData()"
          :docTypeId="docTypeId"
        />
      </template>
      <template v-slot:[`item.date_of_issue`]="{ item }">
        {{ formatDate(item.date_of_issue) }}
      </template>
      <template v-slot:[`item.price_list.inspection_price`]="{ item }">
        {{ item.price_list.inspection_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.price_list.third_insurance_price`]="{ item }">
        {{ item.price_list.third_insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.price_list.tax_price`]="{ item }">
        {{ item.price_list.tax_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.price_list.insurance_price`]="{ item }">
        {{ item.price_list.insurance_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.price_list.other_price`]="{ item }">
        {{ item.price_list.other_price | numeral('0,0.00') }}
      </template>
      <template v-slot:[`item.net_price`]="{ item }">
        {{ item.net_price | numeral('0,0.00') }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="print(item)">
                <v-list-item-title>พิมพ์</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="dialogResetData = {
                  document_id: item._id,
                  note: '',
                }, dialogReset = true">
                <v-list-item-title>รีเซ็ท</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:footer>
        <table-pagination-custom
          :page="filter.page"
          :itemsPerPage="filter.item_per_page"
          :length="pageCount"
          @change-page="onChangePage"
          @change-items-per-page="onChangeitemsPerPage"
        />
      </template>
    </v-data-table>
    
    <v-dialog persistent scrollable max-width="500" v-model="dialogReset">
      <DocumentResetWithNote
        :item="dialogResetData"
        @onClose="dialogReset = false"
        @onSubmit="onSubmitReset"
      />
    </v-dialog>

    <PrintDocument
      :key="printKey"
      v-if="usedPrint && lists.setting.receipt_printing_format == 0"
      :docTypeId="docTypeId"
      :type="docType"
      :data="dataPrint.main_bill"
      :data2="dataPrint.split_bill"
      ref="printDocument"
      :disabled="!usedPrint"
      :firstCreated="true"
    />
    <PrintDocument9inc
      :key="printKey"
      v-if="usedPrint && lists.setting.receipt_printing_format == 2"
      :docTypeId="docTypeId"
      :type="docType"
      :data="dataPrint.main_bill"
      :data2="dataPrint.split_bill"
      ref="printDocument"
      :disabled="!usedPrint"
      :firstCreated="true"
    />
    <PrintDocument80mm
      :key="printKey"
      v-if="usedPrint && lists.setting.receipt_printing_format == 1"
      :docTypeId="docTypeId"
      :type="docType"
      :data="dataPrint.main_bill"
      :data2="dataPrint.split_bill"
      ref="printDocument"
      :disabled="!usedPrint"
      :firstCreated="true"
    />

    <PrintDocumentFull
      :key="printKey"
      v-if="usedPrint && lists.setting.receipt_printing_format == 3"
      :docTypeId="docTypeId"
      :type="docType"
      :data="dataPrint.main_bill"
      :data2="dataPrint.split_bill"
      ref="printDocument"
      :disabled="!usedPrint"
      />
  </div>
</template>

<script>
import PrintDocument from '@/components/PrintDocument/printDocument.vue';
import PrintDocument9inc from '@/components/PrintDocument/printDocument9inc.vue';
import PrintDocument80mm from '@/components/PrintDocument/printDocument80mm.vue';
import PrintDocumentFull from '@/components/PrintDocument/printDocumentFull.vue';
import DatePicker from '@/components/DatePicker.vue';
import DocumentResetWithNote from '@/components/DocumentResetWithNote.vue';
import * as moment from 'moment';

export default {
  components: {
    PrintDocument,
    PrintDocument9inc,
    PrintDocument80mm,
    PrintDocumentFull,
    DatePicker,
    DocumentResetWithNote,
  },
  data: () => ({
    printKey: 0,
    toDay: false,
    docTypeId: 1,
    docType: 'ใบแจ้งหนี้', ////
    docPath: 'invoice',
    dataPrint: {
      main_bill: null,
      split_bill: null,
    },
    loading: true,
    awaitingSearch: false,
    pageCount: 0,
    list: [],
    menu: false,
    dialogReset: false,
    dialogResetData: {
      document_id: '',
      note: '',
    },
    headers: [
      { text: '#', value: '_id', filterable: false, sortable: false, width: 0},
      { text: 'วันที่', value: 'date_of_issue', width: 0},
      { text: 'เลขที่เอกสาร', value: 'number', filterable: false },
      { text: 'ชื่อลูกค้า', value: 'customer_name' },
      { text: 'ชื่อโปรเจ็ค', value: 'project_name', filterable: false },
      { text: 'ตรวจ', value: 'price_list.inspection_price', filterable: false },
      {
        text: 'พรบ.',
        value: 'price_list.third_insurance_price',
        filterable: false,
      },
      { text: 'ภาษี', value: 'price_list.tax_price', filterable: false },
      {
        text: 'ประกันภัย',
        value: 'price_list.insurance_price',
        filterable: false,
      },
      { text: 'อื่น ๆ', value: 'price_list.other_price', filterable: false },
      { text: 'ยอดรวมสุทธิ', value: 'net_price', filterable: false },
      {
        text: 'สถานะ',
        value: 'status',
        sortable: false,
        filterable: false,
        align: 'center',
      },
      { text: '', value: 'actions', sortable: false, filterable: false },
    ],
    status: [
      { text: 'สถานะ', value: '' },
      { text: 'รอดำเนินการ', value: 0 },
      { text: 'เปิดบิลแล้ว', value: 1 },
      { text: 'ยกเลิก', value: 2 },
    ],
    filter: {
      branch_id: 0,
      document_type: 0, //
      status: '',
      item_per_page: 20,
      page: 1,
      start_with: '',
      start_date: moment().add(-12, 'month').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
    },
    usedPrint: false,
    lists: {
      setting: null
    },
    thisBranch: {}
  }),
  created() {
    this.filter.document_type = this.docTypeId;
    this.filter.branch_id = this.$store.state.selected_branch._id;
    this.getData();
    this.getSetting();
    if (this.$route.query._id) {
      this.print({_id: this.$route.query._id})
      this.$router.replace({query:{}})
    };
  },

  methods: {
    changeToday(val) {
      if (val == true) {
        this.filter.start_date = moment().format('YYYY-MM-DD');
        this.filter.end_date = moment().format('YYYY-MM-DD');
      } else {
        this.filter.start_date = moment().add(-12, 'month').format('YYYY-MM-DD');
        this.filter.end_date = moment().format('YYYY-MM-DD');
      }
      this.getData();
    },
    add() {
      this.$router.push({ name: 'document-quotation-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'document-invoice-update',
        params: { id: item._id },
      });
    },
    async reset() {
      let body = {
        token: this.$jwt.sign(
          { 
            document_id: this.dialogResetData.document_id,
            note: this.dialogResetData.note,
          },
          this.$privateKey,
          { noTimestamp: true, }
        ),
      };
      await this.$axios
        .post(`${this.$baseUrl}/document/reset_document`, body)
        .then((res) => {
          if (res.status == 'overdue')
            this.$alertServerError({
              title:
                'เกินระยะเวลารีเช็ต หากต้องการรีเช็ต โปรดใช้บัญชี เจ้าของ',
            });
          this.getData();
        })
        .catch((err) => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
    },
    download(item) {
      this.$router.push({
        name: 'document-invoice-update',
        params: { id: item._id },
        query: { download: true },
      });
    },
    async getSetting() {
      try {
        let listFiltered = this.$store.state.list.branchs.filter((el) => {
          return el._id == this.$store.state.selected_branch._id;
        });

        if (listFiltered.length > 0) {
          this.lists.setting = listFiltered[0].setting;
          this.thisBranch = listFiltered[0];
        } else {
          console.error('No matching branch found.');
        }
      } catch (err) {
        console.error('Error in getSetting:', err);
      }
    },
    async print (item) {
      const {_id: id} = item
      this.usedPrint = true
      let branch = this.thisBranch;
      if (typeof id !== 'undefined') {
        let filter = {
          document_id: id,
          branch: {
            is_split_bill: branch.is_split_bill,
            logo_url: branch.logo_url,
            open_hours: branch.open_hours,
            name_for_receipt: branch.name_for_receipt,
            taxpayer_number: branch.taxpayer_number,
            phone_number: branch.phone_number,
            line_id: branch.line_id,
            facebook: branch.facebook,
            location: branch.location,
            company_branch: branch.company_branch,
            qr_code_payment: branch.qr_code_payment,
            split_bill_data: branch.split_bill_data,
            setting: {
              merging_subcategory: branch.setting.merging_subcategory,
              receipt_printing_format: branch.setting.receipt_printing_format,
              enable_product_merging: branch.setting.enable_product_merging,
            },
            split_bill_category: branch.split_bill_category,
          }
        };
        let body = {
          token: this.$jwt.sign(filter, this.$privateKey, {
            noTimestamp: true,
          }),
        };
        await this.$axios
          .post(`${this.$baseUrl}/document/print_document_data`, body)
          .then(async (res) => {
            this.dataPrint = res.result;
            this.printKey++;
            setTimeout(() => {
              this.$refs.printDocument.print();
            }, 0.5);
          })
          .catch((err) => {
            console.error('err', err);
          });
      }
    },
  async getData(reload = false) {
      try {
        if (reload) {
          this.onChangeitemsPerPage(this.filter.item_per_page);
          this.filter.page = 1;
        }
        this.filter.end_date = moment(this.filter.end_date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        this.loading = true;
        let body = {
          token: this.$jwt.sign(this.filter, this.$privateKey, { noTimestamp: true }),
        };
        const res = await this.$axios.post(`${this.$baseUrl}/document/get_document`, body);
        this.list = res.result.data;
        this.pageCount = res.result.total_page || 1;
      } catch (err) {
        console.error('Error fetching data:', err);
        this.$alertServerError({ title: err.error_message });
      } finally {
        this.loading = false;
      }
    },
    onChangePage(page) {
      if (this.filter.page !== page) {
        this.filter.page = page;
        this.getData();
      }
    },
    onChangeitemsPerPage(number) {
      if (this.filter.item_per_page !== number) {
        this.filter.item_per_page = number;
        this.filter.page = 1;
        this.getData();
      }
    },
    formatDate(date) {
      if (!date) {
        return '';
      }
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      if (m_d === 'Invalid date') return '';
      return `${m_d}/${year + 543}`;
    },
    onSubmitReset(val) {
      this.dialogReset = false;
      this.dialogResetData = {
        document_id: val.document_id,
        note: val.note,
      }
      this.menu = false
      this.reset();
    },
    handleEnter() {
      this.filter.page = 1;
      this.filter.start_date = moment().add(-1, 'years').format('YYYY-MM-DD');
      this.getData(true);
    },
  },
  watch: {
    'filter.start_with': function (val) {
      if (val === "") {
          this.filter.start_date = moment().add(-7, 'day').format('YYYY-MM-DD');
          this.filter.page = 1;
          this.getData(true);
        }
    },
  },
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
